html,
body,
#root {
    height: 100%;
}
body {
    margin: 0;
    padding: 0;
    /*background-color: #212121;*/
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

p {
    line-height: 1.5em;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

body,
h1,
h2,
h3 {
    /* This default font list is duplicated in App.tsx because <CssBaseLine/> effectively
     * installs another font-family list for body.  We need Andika in the list to cover
     * some new/rare characters used by some languages. (BL-11186) */
    font-family: "Roboto", "Noto", "Andika", "Open Sans", sans-serif,
        "Noto Serif Toto"; /* Feb 2023 added toto for the Toto language */
}
h1 {
    text-align: left;
    font-size: 14pt;
    font-weight: 500;
    margin-bottom: 5px;
    /*color: whitesmoke;*/
}
h2 {
    text-align: left;
    font-size: 12pt;
    font-weight: bold;
}

@media (hover) {
    a:hover {
        text-decoration: underline;
    }
}

a:hover {
    cursor: pointer;
}

.banner {
    display: flex;
    flex-direction: column;
    background-position: left;
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    color: black;
    /* padding-left: 20px; */
    flex-shrink: 0;
}

.banner a {
    color: white;
}

/* Rules applying only to ContentfulMultiPartPage. */
.multipart-contentful-page > div {
    display: flex;
    flex-direction: row;
    padding-top: 32px;
    padding-bottom: 32px;
}

/* Rules for the new ContentfulMultiPartPage About page. */
.multipart-contentful-page.new-about {
    padding-left: unset;
    padding-right: unset;
}

.multipart-contentful-page.new-about h1 {
    text-align: center;
}

.multipart-contentful-page.new-about img {
    max-width: 250px;
}

/* Get the multi-layered list of features looking right. */
.multipart-contentful-page.new-about ul {
    list-style: inside;
    margin-left: 20px;
    list-style-type: square;
}

.multipart-contentful-page.new-about ul ul {
    list-style-type: circle;
}

/* New About page feature table rules */
.multipart-contentful-page.new-about table {
    margin-top: 36px;
}

.multipart-contentful-page.new-about thead th {
    width: 30%;
}

.multipart-contentful-page.new-about td {
    vertical-align: top;
}

.multipart-contentful-page.new-about td img {
    height: 150px;
    width: auto;
}

/*
    These next two rules setup CSS that differs by which column this "Part" is for.
    "justify-content" handles vertical alignment. The left column needs to align to the top
    and the right column looks better if it's centered vertically.
    "align-items" handles the horizontal alignment. The left column needs to align left and
    the right columns needs to be center aligned.
*/
.new-about .contentful-markdown-part {
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 0;
}

.new-about .contentful-markdown-part.rightColumn {
    justify-content: center;
    align-items: center;
    padding-left: 40px;
}

/* Bloom Reader page rules */
.contentful-page.bloom-reader img {
    width: 176px;
}
.contentful-page.bloom-reader img[src*="en_badge_web_generic.png"] {
    /* for some reason, the English version has a left/right margin but the others don't */
    width: 200px;
    margin: -12px;
}
